import React from 'react'

class CalendarForm extends React.Component {
  render () {
    const { onSubmit, onClick } = this.props

    return (
      <>
        <form onSubmit={onSubmit}>

          <input
            className={'input'}
            placeholder={'Imię'}
            name={'firstName'}
          />
          <input
            className={'input'}
            placeholder={'Nazwisko'}
            name={'lastNAme'}
          />
          <input
            className={'input'}
            placeholder={'E-mail'}
            type={'email'}
          />
          <input
            className={'input'}
            type={'date'}
          />
          <input
            className={'input'}
            type={'time'}
          />
          <br></br>
          <input
            value={'Zapisz'}
            className={'button input'}
            type={'submit'}
          >
          </input>
        </form>

        <button
          className={'button'}
          onClick={onClick}
        >Wyświetl zaplanowane spotkania
        </button>

      </>
    )
  }
}

export default CalendarForm
