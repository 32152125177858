import React from 'react'

class CalendarList extends React.Component {
  render () {
    const { meetings, onDelete, status } = this.props

    return (
      <section className={'section__meetings'}>
        <h2>{status}</h2>
        <ul>
          {meetings.map((element, index) => {
            return (
              <li key={index}>
                {element[1].firstName} {element[1].lastName}{' '}
                <span style={{ color: 'white' }}>{element[1].email}</span>{' '}
                {element[1].date}{' '}
                <span style={{ color: 'white' }}>{element[1].time}</span>{' '}
                <button
                  className={'input'}
                  data-id={element[0]}
                  onClick={onDelete}
                >
                  Delete
                </button>
              </li>
            )
          })}
        </ul>
      </section>
    )
  }
}

export default CalendarList
