import React from 'react'
import CalendarForm from './CalendarForm'
import CalendarList from './CalendarList'

const apiUrl =
  'https://calendar-dawid-default-rtdb.europe-west1.firebasedatabase.app/meetings/.json'

// const apiUrl = 'http://localhost:3005/meetings'

class Calendar extends React.Component {
  state = {
    meetings: [],
    status: ''
  };

  sendForm (e) {
    if (this.state.meetings.length > 10) {
      alert('Przekroczono pamięć. Usuń spotkania.')
    } else {
      console.log(e.target)
      e.preventDefault()

      if (
        e.target[0].value === '' ||
        e.target[1].value === '' ||
        e.target[2].value === '' ||
        e.target[3].value === '' ||
        e.target[4].value === ''
      ) {
        alert('Uzupełnij wszystkie pola!')
      } else {
        const data = {
          firstName: e.target[0].value,
          lastName: e.target[1].value,
          email: e.target[2].value,
          date: e.target[3].value,
          time: e.target[4].value,
          id: ''
        }

        const options = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        }

        fetch(apiUrl, options)
          .then(() => this.LoadDataFromApi())
          .then(() => {
            e.target[0].value = ''
            e.target[1].value = ''
            e.target[2].value = ''
            e.target[3].value = ''
            e.target[4].value = ''
          })
          .catch((err) => console.log(err))
      }
    }
  }

  LoadDataFromApi () {
    fetch(apiUrl)
      .then((resp) => {
        if (resp.ok) {
          return resp.json()
        }
        return Promise.reject(resp)
      })
      .then((data) => {
        if (data === null || data === undefined) {
          this.setState({
            meetings: [],
            status: 'BRAK SPOTKAŃ!'
          })
        } else {
          this.setState({
            meetings: Object.entries(data),
            status: 'SPOTKANIA'
          })
        }

        console.log('Dane pobrane bezpośrednio z bazy')
        console.log(Array(data))

        console.log('Dane zamienione na tablice')
        console.log(this.state.meetings)
      })
      .catch((err) => console.log(err))
  }

  deleteMeetingFromApi (e) {
    console.log(e.target.dataset.id)

    const id = e.target.dataset.id
    console.log(typeof id)

    const options = {
      method: 'DELETE'
    }

    fetch(
      `https://calendar-dawid-default-rtdb.europe-west1.firebasedatabase.app/meetings/${id}/.json`,
      options
    )
      .then((resp) => console.log(resp))
      .catch((err) => console.log(err))
      .then(() => this.LoadDataFromApi())
  }

  render () {
    return (
      <>
        <h2>Zapisz spotkanie</h2>
        <CalendarForm
          onClick={this.LoadDataFromApi.bind(this)}
          onSubmit={this.sendForm.bind(this)}
        >
        </CalendarForm>
        <CalendarList
          status={this.state.status}
          meetings={this.state.meetings}
          onDelete={this.deleteMeetingFromApi.bind(this)}
        >
        </CalendarList>
      </>
    )
  }
}

export default Calendar
