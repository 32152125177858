import React from 'react'
import Calendar from './components/Calendar'

export const App = () => {
  return (
    <div className={'App'}>
      <h1>Kalendarz spotkań</h1>
      <Calendar/>

    </div>
  )
}

export default App
